.App {
  background-color: #202124;
  color: white;
  position: absolute;
  left: 0;
  width: 100%;
}

.row {
  display: flex;
  padding-top: 5px;
}

.column {
  float: left;
}

.left {
  text-align: center;
  margin: auto;
  width: 50%;
  padding-top: 7%;
  padding-left: 0%;
  max-width: 50%;
}

.right {
  text-align: center;
  margin: auto;
  padding-top: 8%;
  width: 50%;
  max-width: 50%;
}
